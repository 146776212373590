@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 62.5%;
}

.psc-safe-area {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
    env(safe-area-inset-left);
}

.psc-modal {
  padding: calc(2.4rem + env(safe-area-inset-top)) calc(2.4rem + env(safe-area-inset-right))
    calc(2.4rem + env(safe-area-inset-bottom)) calc(2.4rem + env(safe-area-inset-left));
}

body {
  @apply bg-whitey-gray;
  font-family: Inter, sans-serif;
  @apply text-base;
}

.psc-rotate-90 {
  transform: rotate(90deg) !important;
}

.psc-rotate-180 {
  transform: rotate(180deg) !important;
}

.psc-rotate-270 {
  transform: rotate(270deg) !important;
}

:root {
  --toastify-text-color-light: #667085;
  --toastify-color-success: #4c804c;
  --toastify-color-error: #f04438;
  --toastify-spinner-color: #667085;
  --toastify-spinner-color-empty-area: #d0d5dd;
}

input[type='time'] {
  -webkit-appearance: none;
  -moz-appearance: none;
}
